<template>
  <div class="HomeWork">
    <div class="tabs">
      <ul>
        <li @click="_tabsActive(0)" :class="tabsActive == 0 ? 'active' : ''">
          教案分类
        </li>
        <li @click="_tabsActive(1)" :class="tabsActive == 1 ? 'active' : ''">
          教案课件
        </li>
      </ul>
    </div>
    <div class="container">
      <div class="tree" v-if="tabsActive == 0">
        <div class="tree-title">
          教案分类
        </div>
        <div
          class="tree-title"
          style="cursor: pointer; padding-left: 10px;margin-top: 10px;"
          @click="_allData()"
        >
          全部
        </div>
        <a-tree
          v-show="tabsActive == 0"
          :treeData="categories"
          defaultExpandAll
          @select="_treeSelect2"
        />
      </div>
      <div class="content" v-show="tabsActive == 0">
        <div class="row-title">
          <span>分类（{{ tableDataCount }}）</span>
        </div>
        <div class="template-list">
          <div class="search-div">

            <div class="condition">
              <span>学习进度</span>
              <a-select
                style="width: 89px; margin-right: 15px"
                v-model="searcheSchedule_1"
              >
                <a-select-option
                  :value="item"
                  v-for="(item, index) in Progress0"
                  >{{ item }}
                </a-select-option>
              </a-select>

              <a-select
                style="width: 89px; margin-right: 15px"
                v-model="searcheSchedule_2"
              >
                <a-select-option
                  :value="item"
                  v-for="(item, index) in Progress1"
                  >{{ item }}
                </a-select-option>
              </a-select>

              <a-select style="width: 90px" v-model="searcheSchedule_3">
                <a-select-option :value="item" v-for="item in Progress2"
                  >{{ item }}
                </a-select-option>
              </a-select>
            </div>

            <div class="condition" style="white-space: nowrap">
              <span>标题</span>
              <a-input
                v-model="qTitle"
                style="width: 180px; margin-right: 15px"
                placeholder="搜索标题"
              ></a-input>
            </div>
            <div class="condition" style="white-space: nowrap">
            <a-button style="margin-right: 20px" @click="_reset()"
              >重置
            </a-button>
            <a-button type="primary" @click="_searchData" style="margin-right: 20px">查询</a-button>
            <a-button type="primary" @click="addplan" style="margin-right: 20px;background-color: #FD6D3A;border-color: #FD6D3A"
            >添加教案
            </a-button>
              <a-button type="primary" @click="addCategory" style="background-color: #56AF3D;border-color: #56AF3D"
              >添加分类
              </a-button>
            </div>
          </div>
          <div style="margin-top: 20px; margin-right: 20px">
            <a-table
              bordered
              :columns="tableColumns"
              :dataSource="tableData"
              :pagination="false"
            >
              <div slot="Title" slot-scope="text, record, index">
                <div>{{ text }}</div>
              </div>
              <div slot="status" slot-scope="text, record, index">
                <span
                  class="status-icon"
                  :style="
                    text == '禁用'
                      ? 'background: #E81B1C'
                      : 'background: #4199F3'
                  "
                ></span>
                {{ text }}
              </div>
              <div
                slot="operation"
                slot-scope="text, record, index"
                style="width: 100px"
              >
                <i
                  class="iconfont icon-bianji icon-btn"
                  style="font-size: 20px"
                  @click="_setItem(index)"
                ></i>
                <a-popconfirm
                  title="您确定要删除吗?"
                  @confirm="_delTableRow(record.Id)"
                  okText="确定"
                  cancelText="取消"
                >
                  <i class="iconfont icon-shanchu- icon-btn"></i>
                </a-popconfirm>
              </div>
            </a-table>
            <div class="pagination" v-if="tableDataCount > 10">
              <a-pagination
                :total="tableDataCount"
                style="display: inline-block; margin-top: 20px"
                @change="_tablePage"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="content" v-show="tabsActive == 1">
        <div class="row-title">
          <span>课件（{{ FileCount }}）</span>
        </div>
        <div class="template-list">
          <div class="search-div" style="display: flex">
            <div class="condition" style="white-space: nowrap">
              <span>文件名</span>
              <a-input
                      v-model="FileName"
                      style="width: 180px; margin-right: 15px"
                      placeholder="搜索文件名"
              ></a-input>
            </div>
            <div class="condition">
              <span>同步平台</span>
              <a-select
                      style="width: 89px; margin-right: 15px"
                      v-model="PlatformTypes_value"
              >
                <a-select-option
                        :value="item.key"
                        v-for="(item, index) in PlatformTypes"
                >{{ item.name }}
                </a-select-option>
              </a-select>
            </div>
            <div class="condition">
              <span>同步状态</span>
              <a-select
                      style="width: 89px; margin-right: 15px"
                      v-model="uploadStatus_value"
              >
                <a-select-option
                        :value="item.key"
                        v-for="(item, index) in uploadStatus"
                >{{ item.name }}
                </a-select-option>
              </a-select>
            </div>
            <div class="condition">
              <span>文件类型</span>
              <a-select
                      style="width: 89px; margin-right: 15px"
                      v-model="FileMedia_value"
              >
                <a-select-option
                        :value="item.key"
                        v-for="(item, index) in FileMedia"
                >{{ item.name }}
                </a-select-option>
              </a-select>
            </div>
            <div class="condition" style="white-space: nowrap;display: flex">
              <a-button style="margin-right: 20px" @click="_reset()"
              >重置
              </a-button>
              <a-button type="primary" @click="_fileData(1,1)" style="margin-right: 20px">查询</a-button>
              <a-upload action="httpss://sapi.sikegroup.com/v1/teachingPlan/upload" :headers="headers"  @change="uploadhandleChange" :data="{type:'add'}">
                <a-button type="primary"> <a-icon type="upload" /> 添加课件</a-button>
              </a-upload>
              <a-upload action="https://sapi.sikegroup.com/v1/teachingPlan/upload" directory :headers="headers" @change="uploadhandleChange" :data="{type:'add'}">
                <a-button type="primary" style="background-color: #56AF3D;border-color: #56AF3D"><a-icon type="upload" />批量添加课件</a-button>
              </a-upload>

                <a-button type="primary" @click="batchSync" style="background-color: #FD6D3A;border-color: #FD6D3A"><a-icon type="upload" />批量同步课件</a-button>
            </div>
          </div>
          <div style="margin-top: 20px; margin-right: 20px">
            <a-table
                    bordered
                    :row-selection="rowSelection"
                    :columns="FileColumns"
                    :dataSource="FileData"
                    :pagination="false"
            >
              <div slot="Title" slot-scope="text, record, index">
                <div>{{ text }}</div>
              </div>
              <template slot="UploadStatus" slot-scope="text, record, index">
                <span v-if="text === 0"><a-tag color="orange">待同步</a-tag></span>
                <span v-if="text === 1"><a-tag color="green">已同步</a-tag></span>
                <a-tooltip :title="record.UploadResult">
                  <span v-if="text === 2"><a-tag color="red">同步失败?</a-tag></span>
                </a-tooltip>

              </template>
              <template slot="CreateUserName" slot-scope="text, record, index">
                <span>{{record.CreateUserName}}/{{record.CreateTime}}</span>
              </template>
              <template slot="FileUrl" slot-scope="text, record, index">

                <div v-if="record.FileMedia.indexOf('image')!=-1">
                  <img :src="record.FileUrl" style="width: 100%;height: 112px" @click="previewFile(record)"/>
                </div>
                <div>
                  <video width="100%" height="100%" controls="controls" :src="record.FileUrl"
                         @click="previewFile(record)" v-if="record.FileMedia.indexOf('video/mp4')!=-1||record.FileMedia.indexOf('audio/mp3')!=-1||record.FileMedia.indexOf('audio/mpeg')!=-1">
<!--                    <source :src="record.FileUrl" type="video/mp4">-->
                    您的浏览器不支持Video标签。
                  </video>
                </div>
              </template>
              <div
                      slot="operation"
                      slot-scope="text, record, index"
                      style="width: 100px;margin: auto;display: flex;justify-content: center;text-align: center;align-items: center"
              >
                  <div >
                    <a-spin v-show="!isSync"/>
                    <a-tag v-show="isSync" color="#2db7f5" v-if="record.UploadStatus===0" style="cursor: pointer" @click="syncFile(record.Id)">点击同步</a-tag>
                  </div>
                <i
                        class="iconfont icon-bianji icon-btn"
                        style="font-size: 20px"
                        @click="_setFileItem(record.Id)"
                ></i>
              </div>
            </a-table>
            <div class="pagination" v-if="FileCount > 10">
              <a-pagination
                      :total="FileCount"
                      style="display: inline-block; margin-top: 20px"
                      :current="current"
                      @change="_filePage"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="file-preview">
      <a-modal
              title="预览"
              :closable="false"
              :visible="preview_visible"
              :width="800"
              @cancel="preview_close"
              :footer="null"
      >
        <div v-if="preview_fileMedia.indexOf('image')!=-1">
          <img :src="preview_fileUrl" style="width: 100%;height: 100%"/>
        </div>
        <div>
          <video width="100%" height="100%" controls="controls" v-if="preview_fileMedia.indexOf('video/mp4')!=-1||preview_fileMedia.indexOf('audio/mp3')!=-1||preview_fileMedia.indexOf('audio/mpeg')!=-1">
            <source :src="preview_fileUrl" type="video/mp4">
            您的浏览器不支持Video标签。
          </video>
        </div>
      </a-modal>
    </div>
    <!--教案分类弹出框-->
    <a-drawer
            title="教案分类"
            placement="right"
            width="1180"
            :closable="false"
            @close="_categoryClose"
            :visible="categoryVisible"
    >
      <div style="padding-bottom: 55px; display: flex; height: 100%">
        <div style="flex: 1; height: 100%; overflow-y: scroll">
          <div style="position: relative">
            <div class="title-row">
              <span class="title-row-label">名称</span>
              <a-input placeholder="输入名称" v-model="Category.Name"/>
            </div>
            <div class="title-row">
              <span class="title-row-label">标签</span>
              <a-input placeholder="输入标签" v-model="Category.Label"/>
            </div>
            <div class="title-row">
              <span class="title-row-label">教案类型</span>
              <a-tree-select
                      v-model="Category.CategoryId"
                      style="width: 100%"
                      :replace-fields="{value:'key',key:'key',title:'title'}"
                      :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                      :tree-data="categories"
                      placeholder="请选择（无）"
                      @change="selectCategory"
              >
              </a-tree-select>
            </div>
            <div class="title-row">
              <span class="title-row-label">显示数量</span>
              <a-input-number  v-model="Category.ShowNum" :min="0" />
            </div>
          </div>
        </div>
        <div class="alert-footer">
          <a-button style="margin-right: 25px" @click="_categoryClose"
          >取消
          </a-button>
          <a-button type="primary" @click="_categorySub">确定</a-button>
        </div>
      </div>
    </a-drawer>
    <!--教案内容弹出框-->
    <a-drawer
      title="教案内容"
      placement="right"
      width="1180"
      :closable="false"
      @close="_titleClose"
      :visible="titleVisible"
    >
      <div style="padding-bottom: 55px; display: flex; height: 100%">
        <div style="flex: 1; height: 100%; overflow-y: scroll">
          <div style="position: relative">
            <div class="title-row">
              <span class="title-row-label">标题</span>
              <a-input placeholder="输入标题" v-model="detailData.Name"/>
            </div>
            <div class="title-row">
              <span class="title-row-label">教案类型</span>
              <a-tree-select
                      v-model="detailData.Category"
                      style="width: 100%"
                      :replace-fields="{value:'key',key:'key',title:'title'}"
                      :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                      :tree-data="categories"
                      placeholder="请选择"
                      @change="selectCategory"
              >
              </a-tree-select>
            </div>
            <div class="title-row">
              <span class="title-row-label">年进度</span>
              <a-input-number id="Year" v-model="detailData.Year" :min="0" />
            </div>
            <div class="title-row">
              <span class="title-row-label">月进度</span>
              <a-input-number id="Month" v-model="detailData.Month" :min="0" />
            </div>
            <div class="title-row">
              <span class="title-row-label">周进度</span>
              <a-input-number id="Week" v-model="detailData.Week" :min="0" />
            </div>
          </div>
          <div class="title-row">
            <span class="title-row-label">关联课件</span>

            <ul class="title-row">
              <li v-for="(item, index) in lists" style="width: 190px;height:140px;padding: 10px">
                <a-icon type="close" style="cursor: pointer;" @click="deleteFile(item.Id)"/>
                <div class="template-img">
                  <video width="100%" height="100%" controls="controls"
                         :src="item.FileUrl"
                         v-if="item.FileMedia.indexOf('video/mp4')!=-1||item.FileMedia.indexOf('audio/mp3')!=-1">
<!--                    <source :src="item.FileUrl" type="video/mp4">-->
                    您的浏览器不支持Video标签。
                  </video>
                  <div v-else-if="item.FileMedia.indexOf('image')!=-1">
                    <a-icon type="close" />
                    <img :src="item.FileUrl"  style="width: 100%"/>
                  </div>
                  <a-icon type="file" theme="twoTone" :style="{ fontSize: '100px', color: '#08c' }" v-else/>
                </div>
                <div class="template-title flex">
                  <span v-text="item.FileName"></span>
                </div>
                <div class="created-date" v-text="item.CreateTime"></div>
              </li>
            </ul>
            <div style="margin-left: 20px;"><a-icon type="diff" theme="twoTone" :style="{ fontSize: '50px', color: '#08c' }" @click="seleTemplate = true"/></div>
          </div>
        </div>
        <div class="alert-footer">
          <a-button style="margin-right: 25px" @click="_titleClose"
            >取消
          </a-button>
          <a-button type="primary" @click="_titleSub">确定</a-button>
        </div>
      </div>
    </a-drawer>
    <!--选择模板弹出框-->
    <a-drawer
      title="选择课件"
      placement="right"
      width="1080"
      :closable="false"
      @close="_seleTemplateClose"
      :visible="seleTemplate"
    >
      <div class="HomeWork">
        <div class="container" style="margin-top: 0">
          <div class="tree">
            <div class="tree-title">课件分类</div>
            <a-tree
              :treeData="fileCategories"
              @select="_fileSelect"
            />
          </div>
          <!--模板库-->
          <div class="content">
            <div class="row-title" style="padding-top: 0">
              <span>课件（{{ templateList.length }}）</span>
            </div>
            <div class="template-list">
              <a-checkbox-group
                      @change="_materiaIndex"
                      style="width: 100%"
              >
              <ul class="title-row">
                <li v-for="(item, index) in templateList">
                  <div class="template-img" >
                    <video width="100%" height="100%" controls="controls" v-if="item.FileType.indexOf('video/mp4')!=-1||item.FileType.indexOf('audio/mp3')!=-1">
                      <source :src="item.FileUrl" type="video/mp4">
                      您的浏览器不支持Video标签。
                    </video>
                    <img :src="item.FileUrl" v-else-if="item.FileType.indexOf('image')!=-1" style="width: 100%"/>
                    <a-icon type="file" theme="twoTone" :style="{ fontSize: '100px', color: '#08c' }" v-else/>

                  </div>
                  <div class="template-title flex" >
                    <span v-text="item.FileName"></span>
                    <a-checkbox :value="item"></a-checkbox>

                  </div>
                  <div >
                    <a-tag color="green" v-if="item.FilesId&&item.files.UploadStatus==1">已同步</a-tag>
                    <div v-else>
                      <a-tag color="red"  @click="updateFile(item)">点击同步</a-tag>
                    </div>

                  </div>

                  <div class="created-date" v-text="item.CreateTime"></div>
                </li>
              </ul>
              </a-checkbox-group>
              <div class="pagination" v-if="templateCount > 10">
                <a-pagination
                  :total="templateCount"
                  style="display: inline-block"
                  @change="_templatePage"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="alert-footer">
        <a-button style="margin-right: 25px" @click="_seleTemplateClose"
          >取消
        </a-button>
        <a-button type="primary" @click="_seleAccessoryConfirm">确定</a-button>
      </div>
    </a-drawer>
    <!--图片预览-->
    <viewer :images="viewerList" v-if="showViewer">
      <img v-for="(item, index) in viewerList" :key="index" :src="item" />
    </viewer>
    <!--课件内容弹出框-->
    <a-drawer
            title="课件"
            placement="right"
            width="1180"
            :closable="false"
            @close="_fileClose"
            :visible="filesVisible"
    >
      <div style="padding-bottom: 55px; display: flex; height: 100%">
        <div style="flex: 1; height: 100%; overflow-y: scroll">
          <div style="position: relative">
            <div class="title-row">
              <span class="title-row-label">课件名</span>
              <a-input placeholder="Basic usage" v-model="fileDetail.FileName"/>
            </div>

          </div>
          <div class="title-row" style="width: 200px;">
            <span class="title-row-label">课件替换</span>
            <a-upload
                    name="file"
                    :multiple="true"
                    action="https://sapi.sikegroup.com/v1/teachingPlan/upload"
                    :headers="headers"
                    @change="handleChange"
            >
              <a-button> <a-icon type="upload" /> 上传 </a-button>
            </a-upload>
          </div>
        </div>
        <div class="alert-footer">
          <a-button style="margin-right: 25px" @click="_fileClose"
          >取消
          </a-button>
          <a-button type="primary" @click="_fileSub">确定</a-button>
        </div>
      </div>
    </a-drawer>
  </div>
</template>

<script>

const userInfo = JSON.parse(window.sessionStorage.getItem('userInfo'))
import QRCode from 'qrcodejs2'
import Vue from 'vue'
import Viewer from 'v-viewer' //图片操作
import 'viewerjs/dist/viewer.css'
import VueClipboard from 'vue-clipboard2' //复制
Vue.use(VueClipboard)
Vue.use(Viewer)
Viewer.setDefaults({
  Options: {
    inline: true,
    button: true,
    navbar: true,
    title: true,
    toolbar: true,
    tooltip: true,
    movable: true,
    zoomable: true,
    rotatable: true,
    scalable: true,
    transition: true,
    fullscreen: true,
    keyboard: true,
    url: 'data-source'
  }
})
export default {
  name: 'HomeWork',
  data () {
    return {
      preview_visible:false,
      preview_fileMedia:'',
      preview_fileUrl:'',
      Category: {
        ShowNum:'',
        Name:'',
        Label:'',
        CategoryId:'',
      },
      headers: {
        'X-Requested-With': null
      } ,
      filesVisible:false,
      fileDetail:[],
      FileMedia_value:'',
      current:1,
      filesIds:[],
      isSync:true,
      FileName:'',
      FileData:[],
      FileCount:0,
      fileVisible:false,
      fileIds:[],
      lists:[],
      value: [],
      categories:[],
      fileCategories:[],
      fileTemplateId:'',

      positionData: [],
      Progress0: ['', 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      Progress1: ['', 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
      Progress2: ['', 0, 1, 2, 3, 4],
      tabsActive: 0,
      searchClass: '',
      searcheSchedule_3: '',
      searcheSchedule_2: '',
      searcheSchedule_1: '',
      FileMedia:[
        {key:'',name:'请选择'},
        {key:'jpeg',name:'jpeg'},
        {key:'png',name:'png'},
        {key:'gif',name:'gif'},
        {key:'mp4',name:'mp4'},
        {key:'mp3',name:'mp3'},
        {key:'applicatio',name:'applicatio'},
      ],
      uploadStatus:[
        {key:'',name:'请选择'},
        {key:0,name:'待同步'},
        {key:1,name:'已同步'},
        {key:2,name:'同步失败'},
      ],
      uploadStatus_value:'',
      PlatformTypes:[
        {key:'bjcloud',name:'bjcloud'},
        {key:'classin',name:'classin'},
      ],
      PlatformTypes_value:'',
      setTemplate: -1,
      selectedRowKeys: [],
      tableColumns: [
        {
          title: '序号',
          dataIndex: 'index',
          key: 'index',
          align: 'center',
          width: 100,
          customRender: (text,record,index) => `${index+1}`,
        },
        {
          title: '类型',
          dataIndex: 'Category',
          width: 180,
          scopedSlots: { customRender: 'Category' }
        },
        {
          title: '标题',
          dataIndex: 'Name',
          width: 180,
          align: 'center'
        },
        {
          title: '进度标识',
          dataIndex: 'Progress',
          width: 130,
          align: 'center'
        },
        {
          title: '年进度',
          dataIndex: 'Year',
          width: 100,
          align: 'center'
        },
        {
          title: '月进度',
          dataIndex: 'Month',
          width: 100,
          align: 'center'
        },
        {
          title: '周进度',
          dataIndex: 'Week',
          width: 100,
          align: 'center'
        },
        {
          title: '创建人',
          dataIndex: 'CreateUserName',
          width: 130,
          align: 'center'
        },
        {
          title: '创建时间',
          dataIndex: 'CreateTime',
          width: 160,
          align: 'center'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: 100,
          align: 'center',
          scopedSlots: { customRender: 'operation' }
        }
      ],
      FileColumns: [
        {
          title: '文件名',
          dataIndex: 'FileName',
          width: 130,
          align: 'center'
        },

        {
          title: '文件大小',
          dataIndex: 'FileSize',
          width: 100,
          align: 'center'
        },
        {
          title: '文件类型',
          dataIndex: 'FileMedia',
          width: 130,
          align: 'center'
        },
        {
          title: '文件预览',
          dataIndex: 'FileUrl',
          width: 130,
          align: 'center',
          scopedSlots: { customRender: 'FileUrl'}
        },
        {
          title: '同步平台',
          dataIndex: 'PlatformType',
          width: 100,
          align: 'center'
        },
        {
          title: '同步状态',
          dataIndex: 'UploadStatus',
          width: 100,
          align: 'center',
          scopedSlots: { customRender: 'UploadStatus'} //这里配置关联
        },
        {
          title: '创建人/时间',
          dataIndex: 'CreateUserName',
          width: 150,
          align: 'center',
          scopedSlots: { customRender: 'CreateUserName' },
        },

        {
          title: '操作',
          dataIndex: 'operation',
          width: 100,
          align: 'center',
          scopedSlots: { customRender: 'operation' },
        }
      ],
      tableData: [],
      templateList: [],
      templateCount: 10,
      tableDataCount: 10,
      tablePage: 1,
      filePage:1,
      templatePage: 1,

      materiaData: {
        count: 0,
        list: []
      }, //素材列表
      viewerList: [
        'https://smart-resource.sikegroup.com/FpOfgo6FqKaS6VgjX5n-83Ny2kdx'
      ], //图片预览
      showViewer: false,
      templateVisible: false,
      titleVisible: false,
      categoryVisible: false,
      seleTemplate: false,
      seleAccessory: false,
      container: '',
      detailData: '', //修改详情
      detailIndex: '',
      materiaTemplateIndex: [], //附件
      selectTemplateId: '', //选择模板分类ID


      progress: ['', '', ''], //学习进度
      selectTemplate: '', //模板
      templateIndex: -1,
      materiaIndex: [], //附件Index
      tempdata:[],
      questionList: [
        {
          Title: '',
          List: [
          ]
        }
      ], //数据项

      qTitle: '',
      selectedRows:[],
    }
  },
  created () {
    //获取教案分类
    this.$axios.get(20000, {}, res => {
      if (res.data.code == 1) {
        this.categories = res.data.data
      }
    })

    //获取课件分类
    this.$axios.get(20003, {}, res => {
      if (res.data.code == 1) {
        this.fileCategories = res.data.data
      }
    })

    //获取模板列表
    this._templateData()

  },
  computed: {

    rowSelection() {
      const {selectedRowKeys} = this;
      return {
        selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
          console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
          this.filesIds = [];
          for (let i=0;i<selectedRows.length;i++){
            this.filesIds.push([selectedRows[i]['Id']]);
          }
          this.selectedRowKeys = selectedRowKeys;
        },
        getCheckboxProps: record => ({
          props: {
            disabled: record.name === 'Disabled User', // Column configuration not to be checked
            name: record.name,
          },
        }),
      };
    },
  },
  mounted () {
    this.container = document.getElementsByClassName('HomeWork')[0]
  },
  methods: {
    // 关闭预览
    preview_close(){
      this.preview_visible = false;
      this.preview_fileMedia = ''
      this.preview_fileUrl = ''
    },
    // 打开预览
    previewFile(item){
      this.preview_visible = true;
      this.preview_fileMedia = item.FileMedia
      this.preview_fileUrl = item.FileUrl
    },
    updateFile(item){
      this.$axios.post(
              20010,
              { Id: item.Id},
              res => {
                if (res.data.code == 1) {
                  this.$message.success(res.data.message)
                  this._fileData()
                  this.filesIds = [];
                  this.selectedRowKeys =[];
                  this.selectedRows=[];
                } else {
                  this.$message.error(res.data.message)
                }

              }
      )
    },
    deleteFile(id){
      for (let i=0;i<this.lists.length;i++){
        if(this.lists[i]['Id']==id){
          this.lists.splice(i, 1);
        }
      }

    },
    addCategory(){
      this.categoryVisible = true
    },
    addplan(){
      this.titleVisible = true
      this.detailData =[]
    },
    selectCategory(value, label, extra){
      this.detailData.CategoryId = value
      this.detailData.Category = label[0]
    },
    uploadhandleChange(info) {

      if (info.file.status === 'done') {
        if(info.file.response.code==1){
          this.$message.success(`${info.file.name} ${info.file.response.msg}`);
          this._fileData(1,1);
        }else {
          this.$message.error(`${info.file.name} ${info.file.response.data}.`);
        }

      } else if (info.file.status === 'error') {
        this.$message.error(`${info.file.name} file upload failed.`);
      }
    },
    handleChange(info) {

      if (info.file.status === 'done') {
        if(info.file.response.code==1){
          this.fileDetail.FileName= info.file.response.data.FileName;
          this.fileDetail.FileUrl= info.file.response.data.FileUrl;
          this.fileDetail.FileMedia= info.file.response.data.FileMedia;
          this.fileDetail.FileSize= info.file.response.data.FileSize;
          this.fileDetail.Filetype= info.file.response.data.Filetype;
          this.fileDetail.PlatformType= info.file.response.data.PlatformType;
          this.$message.success(`${info.file.name} ${info.file.response.msg}`);
        }else {
          this.$message.error(`${info.file.name} ${info.file.response.data}.`);
        }

      } else if (info.file.status === 'error') {
        this.$message.error(`${info.file.name} file upload failed.`);
      }
    },
    //批量同步课件
    batchSync(){
      this.$axios.post(
              20006,
              { Id: this.filesIds, UserId: userInfo.uid, UserName: userInfo.name },
              res => {
                if (res.data.code == 1) {
                  this.$message.success(res.data.message)
                  this._fileData()
                  this.filesIds = [];
                  this.selectedRowKeys =[];
                  this.selectedRows=[];
                } else {
                  this.$message.error(res.data.message)
                }

              }
      )
    },
    //同步单个文件
    syncFile(Id){
      this.isSync = true
      this.$axios.post(
              20006,
              { Id: Id, UserId: userInfo.uid, UserName: userInfo.name },
              res => {
                if (res.data.code == 1) {
                  this.$message.success(res.data.message)
                  this._fileData()

                } else {
                  this.$message.error(res.data.message)
                }

              }
      )
    },
    _fileData(page,type){
      if(type){
        this.filePage = 1;
        this.current = 1;
        this.selectedRowKeys = [];
      }
      this.$axios.get(20004,
              {
                CategoryId: this.selectTemplateId,
                Page:page,
                UploadStatus:this.uploadStatus_value,
                PlatformType: this.PlatformTypes_value,
                FileMedia: this.FileMedia_value,
                FileName: this.FileName,
              },
              res => {
                if (res.data.code == 1) {
                  this.FileData = res.data.data
                  this.FileCount = res.data.count
                }else {
                  this.FileData = []
                  this.FileCount = 0
                }
              }
      )
    },
    _treeSelect2 (key) {
      this.tablePage = 1
      this.searchClass = key[0]
      this._searchData(0)
    },
    _allData () {
      if (this.tabsActive == 1) {
        this.selectTemplateId = ''
        this._templateData()
      } else {
        this.searchClass = ''
        this._searchData(0)
      }
    },
    _tablePage (page) {
      this.tablePage = page
      this._searchData(1)
    },
    _filePage(page){
      this.filePage = page
      this.current = page;
      this.selectedRowKeys=[];
      this._fileData(this.current,0)
    },
    _searchData (type) {
      if (type !== 1) {
        this.tablePage = 1
      }

      this.$axios.get(
              20001,
        {
          CategoryId: this.searchClass,
          Page: this.tablePage,
          Year: this.searcheSchedule_1,
          Month: this.searcheSchedule_2,
          Week: this.searcheSchedule_3,
          Title: this.qTitle,
        },
        res => {
          if (res.data.code == 1) {
            this.tableData = res.data.data
            this.tableDataCount = res.data.count
          } else {
            this.tableData = []
            this.tableDataCount = 0
          }
        }
      )
    },
    _materiaIndex (checkedValues) {
      let self = this
      this.materiaIndex = []
      this.value = []
      this.fileIds=[]
      this.tempdata = checkedValues;
      for (let j = 0; j < checkedValues.length; j++) {
        self.materiaIndex.push(self.materiaData.list[checkedValues[j]])
        this.value.push([checkedValues[j]['FileName']])
        this.fileIds.push([checkedValues[j]['FilesId']]);
      }

    },
    _seleAccessoryConfirm () {
      this.seleTemplate = false
      for (let i=0;i<this.tempdata.length;i++){
        let n = 1;
        for (let j=0;j<this.lists.length;j++) {
          if(this.lists[j]['FilesId']===this.tempdata[i]['FilesId']){
            n = n+1;
          }
        }
        if(n===1&&this.tempdata[i]['FilesId']){
          this.lists.push({
            'Id':this.tempdata[i]['FilesId'],
            'FilesId':this.tempdata[i]['FilesId'],
            'FileMedia':this.tempdata[i]['files']['FileMedia'],
            'CreateTime':this.tempdata[i]['files']['CreateTime'],
            'FileName':this.tempdata[i]['files']['FileName'],
            'FileUrl':this.tempdata[i]['files']['FileUrl']})
        }
      }
    },
    _seleTemplateClose () {
      this.seleTemplate = false
      this.templateIndex = -1
    },
    _delTableRow (Id) {
      this.$axios.post(
              20011,
        { Id: Id, UserId: userInfo.uid },
        res => {
          if (res.data.code == 1) {
            this.$message.success('删除成功')
            this._searchData(0)
          }
        }
      )
    },
    _setFileItem (i) {
      this.$axios.get(20008, { Id: i}, res => {
        if (res.data.code == 1) {
          this.fileDetail = res.data.data
          this.detailIndex = i
          let item = this.detailData
          if (item.SupData) {
            this.positionData = JSON.parse(item.SupData)
          }
          this.filesVisible = true

        }
      })
    },
    _setItem (i) {
      this.lists = [];
      this.$axios.get(20002, { Id: this.tableData[i].Id }, res => {
        if (res.data.code == 1) {
          this.detailData = res.data.data
          if(res.data.data.list){
            this.lists = res.data.data.list
          }
          this.detailIndex = i
          let item = this.detailData
          if (item.SupData) {
            this.positionData = JSON.parse(item.SupData)
          }
          this.titleVisible = true
          this.progress = [item.Year, item.Month, item.Week] //学习进度
          this.selectTemplate = item.template_info //选择模板重置
          this.materiaTemplateIndex = item.Enclosure //附件
          this.questionList = item.OptionData //数据项
          if (item.template_info) {
            setTimeout(function () {
              let qrcode = new QRCode('qrcode', {
                width: 100,
                height: 100, // 高度
                text: 'https://q.qitianzhen.cn/homework/workBook?id=' + item.Id
              })
            })
          }
        }
      })
    },
    _fileClose(){
      this.fileDetail = [];
      this.filesVisible = false;
    },
    _fileSub(){

      if (this.fileDetail) {
        let fileIds = [];
        for (let i=0;i<this.lists.length;i++){
          fileIds.push([this.lists[i]['FilesId']])
        }
        this.$axios.post(20008, {Id:this.fileDetail.Id,FileName:this.fileDetail.FileName,FileUrl:this.fileDetail.FileUrl,FileMedia:this.fileDetail.FileMedia,FileSize:this.fileDetail.FileSize}, res => {
          if (res.data.code == 1) {
            this.$message.success('修改成功')
            this.fileDetail = [];
            this.filesVisible = false;
          } else {
            this.$message.error(res.data.message)
          }
        })
      }
    },
    _categorySub(){
      this.$axios.post(20009, this.Category, res => {
        if (res.data.code == 1) {
          if(this.detailData.Id){
            this.$message.success('修改成功')
          }else {
            this.$message.success('添加成功')
          }
          this._categoryClose()
        } else {
          this.$message.error(res.data.message)
        }
      })
    },
    _titleSub () {

      if (this.detailData) {
        let fileIds = [];
        for (let i=0;i<this.lists.length;i++){
          fileIds.push([this.lists[i]['FilesId']])
        }
        this.$axios.post(20005, {
          fileIds:fileIds,
          Id:this.detailData.Id?this.detailData.Id:'',
          Year: this.detailData.Year,
          Month: this.detailData.Month,
          Week: this.detailData.Week,
          CategoryId: this.detailData.CategoryId,
          Category: this.detailData.Category,
          Name: this.detailData.Name}, res => {
          if (res.data.code == 1) {
            if(this.detailData.Id){
              this.$message.success('修改成功')
            }else {
              this.$message.success('添加成功')
            }
            this._titleClose()
          } else {
            this.$message.error(res.data.message)
          }
        })
      }
    },
    _categoryClose(){
      this.categoryVisible = false;
    },
    _titleClose () {
      this.titleVisible = false
      this.progress = ['', '', ''] //学习进度
      this.templateIndex = -1 //选择模板重置

    },
    _fileSelect(key){
      this.fileTemplateId = key[0]
      this.templatePage = 1
      this._filetemplateData()
    },
    _filetemplateData(){
      this.$axios.get(20007,
              { CategoryId: this.fileTemplateId, Page:this.templatePage},
              res => {
                if (res.data.code == 1) {
                  this.templateList = res.data.data
                  this.templateCount = res.data.count
                } else {
                  this.templateList = []
                  this.templateCount = 0
                }
              }
      )
    },
    _templatePage (page) {
      this.templatePage = page
      this._filetemplateData()
    },
    _templateData () {
      this.$axios.get(20004,
        { CategoryId: this.selectTemplateId, Page:this.filePage},
        res => {
          if (res.data.code == 1) {
              this.FileData = res.data.data
              this.FileCount = res.data.count
          } else {
            this.templateList = []
            this.templateCount = 0
          }
        }
      )
    },
    _tabsActive (index) {
      this.tabsActive = index
      this.selectTemplateId = ''

      if (index == 1) {
        this._fileData()
      }
    },
    _reset () {
      this.PlatformTypes_value=''
      this.FileName = ''
      this.qTitle = ''
      this.searcheSchedule_1 = ''
      this.searcheSchedule_2 = ''
      this.searcheSchedule_3 = ''
    },
  }
}
</script>

<style lang="less" scoped>
.HomeWork {
  height: 100%;
  .tabs {

    border-bottom: 1px solid #ccd1dc;
    border-left: 1px solid #ccd1dc;
    border-top-left-radius: 5px;
    ul {
      li {
        display: inline-block;
        width: 85px;
        padding: 6px 0;
        text-align: center;
        border: 1px solid #ccd1dc;
        border-bottom: none;
        cursor: pointer;
        border-left: 0;
        &:first-child {
          border-top-left-radius: 5px;
        }
        &:last-child {
          border-top-left-radius: 0;
          border-top-right-radius: 5px;
        }
      }
      .active {
        background: #1890ff;
        color: #fff;
        border: 1px solid #1890ff;
      }
    }
  }
}

.container {
  min-height: calc(~'100% - 60px');
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
  .content {
    flex: 1;
    margin-left: 15px;
    padding: 15px;
    padding-bottom: 40px;
    border: 1px solid #dfe0e6;
    background: #fff;
    border-radius: 5px;
    .row-title {
      display: flex;
      justify-content: space-between;
      padding-top: 10px;
      span {
        font-size: 16px;
      }
    }

    .template-list {
      padding-top: 15px;
      .search-div {
        .condition {
          display: inline-block;
          margin-right: 20px;
          margin-bottom: 10px;
          span {
            margin-right: 10px;
          }
        }
      }
      .icon-btn {
        font-size: 20px;
        margin-right: 8px;
        cursor: pointer;
      }

      .title-row {
        display: flex;
        flex-wrap: wrap;
        > li {
          position: relative;
          width: 140px;
          margin-right: 10px;
          margin-bottom: 15px;
          .template-img {
            height: 100px;
            overflow: hidden;
            position: relative;
          }
          .template-title {
            padding: 15px;
            padding-bottom: 0;
            font-size: 16px;
            color: #2d3e5d;
            span {
              display: inline-block;
              width: 180px;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
          }
          .created-date {
            padding: 0 15px;
            padding-bottom: 10px;
            color: #999;
          }
          img {
            position: absolute;
            top: 0;
            left: 50%;
            transform: translate(-50%);
            max-width: 100%;
            max-height: 100%;
          }
        }
      }
      .pagination {
        text-align: right;
      }
    }
    .template-library {
      .title-row {
        > li {
          width: 200px;
          margin-right: 15px;
          margin-bottom: 15px;
          .template-img {
            height: 160px;
          }
        }
      }
    }
  }
  .tree {
    width: 340px;
    padding: 15px;
    border: 1px solid #dfe0e6;
    border-radius: 5px;
    background: #fff;
    .tree-title {
    }
  }
}

.status-icon {
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #ddd;
  margin-right: 3px;
}

/*
    *添加题目模板弹出框样式
    */
.parameter {
  position: relative;
  width: 350px;
  padding: 0 20px;
  border-left: 8px solid #f9f9fb;
  .float {
    position: fixed;
  }
  .parameter-title {
    width: 80px;
    height: 30px;
    line-height: 30px;
    margin-bottom: 15px;
    background: #666668;
    border-radius: 5px;
    color: #fff;
    text-align: center;
    font-size: 14px;
  }
  .item-row {
    margin-bottom: 15px;
    .row-label {
      margin-bottom: 10px;
    }
    .row-input {
      position: relative;
      .association-img {
        width: 70px;
        height: 70px;
      }
      .shade {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #000;
        opacity: 0.7;
      }
      .icon-btn {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 20px;
        color: #fff;
      }
      .add-association {
        display: inline-block;
        vertical-align: top;
        width: 70px;
        height: 70px;
        border: 1px dashed #e3e2e5;
        text-align: center;
        cursor: pointer;
        position: relative;
        p {
          margin-top: -15px;
          font-size: 50px;
          color: #aaa;
          font-weight: 300;
        }
        div {
          margin-top: -15px;
          font-size: 12px;
          color: #aaa;
        }
        input {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          opacity: 0;
        }
      }
    }
  }
  .item-row-checkbox {
    margin-bottom: 20px;
  }
}

.title-row {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  .video {
    position: relative;
    border: 1px solid #eee;
    .video-msg {
      padding: 10px;
      border-top: 1px solid #f3f1f3;
      .video-name {
        font-size: 16px;
      }
      .video-length {
        color: #aaa;
        font-size: 14px;
      }
    }
    img {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      max-width: 100%;
      max-height: 165px;
    }
    .play-btn {
      position: absolute;
      top: 40%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 50px;
      color: #666;
      cursor: pointer;
    }
  }
  .audio {
    position: relative;
    display: flex;
    justify-content: space-between;
    padding: 10px;
    border-radius: 5px;
    font-size: 16px;
    .audio-title {
      position: relative;
      flex: 1;
      .audio-upload-time {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        font-size: 12px;
        color: #aaa;
      }
    }
    .audio-file {
      width: 80px;
      padding: 10px 0;
      background: #fafafc;
      text-align: center;
      margin-left: 10px;
    }
    .audio-length {
      color: #aaa;
      font-size: 14px;
    }
  }
  .title-row-label {
    display: inline-block;
    vertical-align: middle;
    width: 80px;
  }
  .advanced-setup {
    padding: 5px 15px;
    border: #d9d9d9 1px solid;
    border-radius: 4px;
    position: relative;
    top: 110px;
    left: 20px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .advanced-setup:hover {
    color: #40a9ff;
    border-color: #40a9ff;
    cursor: pointer;
  }
  .title-row-input {
    display: inline-block;
    vertical-align: middle;
    width: 300px;
    .select-input {
      width: 100%;
      height: 30px;
      padding: 0 10px;
      line-height: 30px;
      background: #fff;
      border: 1px solid #d9d9d9;
      border-radius: 4px;
      cursor: pointer;
      span {
        color: #ddd;
      }
    }
    .upload-attachment {
      position: relative;
      input {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
      }
    }
    .audio,
    .video {
      margin-top: 15px;
    }
  }
}

.template-thumbnail {
  position: absolute;
  top: 15px;
  right: 15px;
  ul {
    li {
      display: inline-block;
      width: 150px;
      height: 150px;
      margin-right: 20px;
      border: 1px solid #e4e3ea;
      border-radius: 5px;
      text-align: center;
      vertical-align: top;
      p {
        margin-top: 5px;
        text-align: center;
        color: #666;
        font-size: 12px;
      }
      img {
        display: inline-block !important;
        margin: 10px 20px;
        width: 85px;
        height: 105px;
      }
      #qrcode {
        margin: 0 auto;
        margin-top: 15px;
        width: 90px;
        height: 90px;
        img {
          padding: 0;
          margin: 0;
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}

.question-msg {
  .title-row-input {
    ul {
      li {
        position: relative;
        display: inline-block;
        width: 100px;
        height: 100px;
        margin-right: 15px;
        margin-bottom: 15px;
        border: 1px solid #e4e4e4;
        vertical-align: top;
        .operation-btn {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          background: #000;
          opacity: 0.7;
          color: #fff;
          display: flex;
          align-items: center;
          span {
            position: relative;
            display: inline-block;
            flex: 1;
            padding: 4px 0;
            text-align: center;
            font-size: 12px;
            cursor: pointer;
            input {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              opacity: 0;
            }
          }
        }
        .itme-empty {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          font-size: 14px;
          width: 100%;
          text-align: center;
          color: #aaa;
        }
        &.add-btn {
          border: 1px dashed #e4e4e4;
          font-size: 40px;
          color: #e4e4e4;
          text-align: center;
          span {
            position: absolute;
            top: 40%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: 90px;
            line-height: 1;
            cursor: pointer;
          }
          input {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
          }
        }
        &.active {
          border: 1px dashed #1890ff;
        }
      }
    }
    .add-row-btn {
      padding: 8px 0;
      border: 1px solid #ececed;
      text-align: center;
      color: #aaa;
      font-size: 12px;
      cursor: pointer;
    }
  }
  .question-msg-title {
    margin-bottom: 15px;
    .label-del {
      margin-left: 15px;
      color: #b6b3b6;
      font-size: 12px;
    }
  }
}

/*
    *上传模板弹出框样式
    */
.label-input {
  margin-top: 5px;
  margin-bottom: 15px;
}

.template-upload-btn {
  position: relative;
  border-color: #1890ff;
  color: #1890ff;
  input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
  }
}

.upload-suggestion {
  color: #a6a5a8;
  font-size: 12px;
}

.alert-footer {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  border-top: 1px solid #e9e9e9;
  padding: 10px 16px;
  background: #fff;
  button {
    width: 120px;
  }
}

.upload-btn {
  position: relative;
  input[type='file'] {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
  }
}

.ant-drawer-body {
  height: calc(~'100% - 55px');
}

/*
    *练习册详情
    */
.workBook-detail {
  width: 100%;
  .tree {
    display: inline-block;
    vertical-align: top;
    margin-right: 10px;
    height: 100%;
    .row {
      display: flex;
      align-items: center;
      margin: 20px 0;
      p {
        width: 70px;
        color: #abb0bf;
      }
      input {
        flex: 1;
      }
    }
  }
}

.crumbs {
  position: relative;
  height: 64px;
  padding-right: 30px;
  line-height: 64px;
  border-radius: 4px;
  background: #fff;
  text-align: center;
  color: #666;
  font-size: 14px;
  .return {
    position: absolute;
    top: 50%;
    left: 25px;
    transform: translateY(-50%);
    font-size: 18px;
    color: #9d9d9d;
    cursor: pointer;
  }
  .crumbs-icon {
    margin-left: 30px;
    margin-right: 30px;
    font-size: 14px;
    color: #9d9d9d;
  }
  .crumbs-item {
    position: relative;
    cursor: pointer;
    span {
      position: absolute;
      bottom: -24px;
      left: 0;
      width: 100%;
      height: 2px;
    }
  }
  .plptext {
    display: inline-block;
    width: 100px;
    height: auto;
    color: #c2c2c2;
  }
}

#advanced-setup-box {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0px;
  z-index: 99999;
  .box-bgcolor {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: #000;
    opacity: 0.3;
  }
  .image-box {
    position: absolute;
    top: 0px;
    right: 0px;
    width: 1024px;
    height: 100%;
    background-color: #fff;
    z-index: 100000;
    .btn-box {
      width: 100%;
      height: 70px;
      line-height: 70px;
      position: absolute;
      bottom: 0;
      left: 0;
      border-top: solid 1px #e3e3e3;
      span {
        border: #e3e3e3 1px solid;
        padding: 7px 20px;
        margin-left: 10px;
        border-radius: 5px;
        cursor: pointer;
      }
    }
    .video-box {
      position: relative;
      height: 667px;
      width: 100%;
      .subject-box {
        position: relative;
        height: 100%;
        width: 375px;
        border: #e3e3e3 1px solid;
        margin: 0 50px;
        box-shadow: rgba(207, 207, 207, 0.5) 0px 0px 12px 2px;
        p {
          font-size: 17px;
          text-indent: 20px;
        }
        .subject-images {
          width: 90%;
          height: auto;
          position: relative;
          // top: 60px;
          left: 50%;
          transform: translate(-50%, 0%);
        }
        .answer-box {
          width: 90%;
          height: 200px;
          position: relative;
          // top: 55%;
          left: 50%;
          transform: translate(-50%, 0%);
          img {
            position: absolute;
            height: auto;
            margin-right: 10px;
          }
          .sub-box {
            position: relative;
            img {
              position: absolute;
              z-index: 100005;
            }
          }
          // .ans-box {
          //   margin-top: 20px;
          //   img {
          //     position: relative;
          //     top: 50px;
          //   }
          // }
        }
      }
      .position-data-box {
        position: absolute;
        right: 224px;
        top: 0px;
        width: 300px;
        height: 100%;
        ul {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          li {
            margin-top: 10px;
            p {
              input {
                width: 50px;
                margin-top: 5px;
                box-shadow: none;
                border: #e3e3e3 1px solid;
              }
              button {
                width: 20px;
                height: 20px;
                text-align: center;
                line-height: 20px;
                border: #e3e3e3 1px solid;
                background-color: #fff;
                margin-left: 5px;
              }
            }
          }
        }
      }
    }
  }
}
</style>
